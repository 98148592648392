import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';

type Props = {
  passengers: any;
  idTravel: number;
};

const PassengersItem: FunctionComponent<Props> = ({ passengers, idTravel }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (passengers.length === 0) return null;

  return (
    <div>
      <div
        onClick={handleClick}
        className="flex gap-2 items-center cursor-pointer hover:bg-gray-200 w-fit py-2 px-3 rounded-full"
      >
        <FontAwesomeIcon icon={faUser} color="#d1d1d1" />
        {passengers.length}
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={3}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        className="ml-3"
        PaperProps={{
          style: {
            borderRadius: 10
          }
        }}
        id={id}
      >
        <TableContainer
          style={{
            maxHeight: 350,
            width: 350
          }}
        >
          <Table aria-label="simple table" stickyHeader size="small">
            <TableHead>
              <TableRow
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <TableCell>
                  <div className="flex justify-between">
                    <p className="text-left text-gray-500">Pasajeros</p>
                    <span className="text-gray-400">#{idTravel}</span>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {passengers.map((passenger) => (
                <TableRow
                  key={passenger}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <TableCell align="left" component="th" scope="passenger">
                    <p className="rounded-md pr-3 text-sm">{passenger}</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Popover>
    </div>
  );
};

export default PassengersItem;
