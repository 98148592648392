import moment, { Moment } from 'moment';

import { VehiculoModeloModel } from '../modeloVehiculo/models/VehiculoModelo';
import { VehiculoCombustible } from './VehiculoCombustible';
import { VehiculoTipo } from './VehiculoTipo';
import { VehiculoCategoria } from './VehiculoCategoria';
import { VehiculoCompaniaSeguro } from './VehiculoCompaniaSeguro';
import { VehiculoMunicipio } from './VehiculoMunicipio';
import { VehiculoColor } from './VehiculoColor';
import { TitularModel } from '../titulares/models/Titular';
import { VehiculoCRUD, VehiculoModel } from './Vehiculo';

export interface VehiculoFormSchemaGeneral {
  fechaIngreso: Moment;
  fotoAuto: File | string;
  patente: string;
  titular: TitularModel;
  modelo: VehiculoModeloModel;
  color: VehiculoColor;
  anio: number;
  chasis: string;
  combustible: VehiculoCombustible;
  carbono: number | string;
  tipo: VehiculoTipo;
  categoria: VehiculoCategoria;
  bus_type_id: number | null;
}

export interface VehiculoFormSchemaVencimiento {
  vencimientoVTV: Moment;
  fotoVTV: File | string;
  companiaSeguro: VehiculoCompaniaSeguro;
  vencimientoPoliza: Moment;
  fotoPoliza: File | string;
  vencimientoHabilitacion: Moment;
  fotoHabilitacion: File | string;
  vencimientoCuotaSeguro: Moment;
  fotoCuotaSeguro: File | string;
  vencimientoMicrocentro: Moment | null;
  fotoMicrocentro?: File | string;
  municipio: VehiculoMunicipio;
}

export interface VehiculoFormSchema {
  general: VehiculoFormSchemaGeneral;
  vencimientos: VehiculoFormSchemaVencimiento;
}

export function vehiculoFormSchemaInitialValues(
  titulares: TitularModel[],
  modelos: VehiculoModeloModel[]
): VehiculoFormSchema {
  return {
    general: vehiculoFormSchemaGeneralInitialValues(titulares, modelos),
    vencimientos: vehiculoFormSchemaVencimientosInitialValues()
  };
}

export function vehiculoFormSchemaGeneralInitialValues(
  titulares: TitularModel[],
  modelos: VehiculoModeloModel[]
): VehiculoFormSchemaGeneral {
  return {
    fechaIngreso: moment(),
    fotoAuto: '',
    patente: '',
    titular: titulares[0]!,
    modelo: modelos[0],
    color: VehiculoColor.NEGRO,
    anio: moment().year(),
    chasis: '',
    combustible: VehiculoCombustible.GNC,
    tipo: VehiculoTipo.EJECUTIVO,
    categoria: VehiculoCategoria.BRONCE,
    carbono: '',
    bus_type_id: null
  };
}

export function vehiculoFormSchemaVencimientosInitialValues(): VehiculoFormSchemaVencimiento {
  return {
    vencimientoVTV: moment(),
    fotoVTV: '',
    companiaSeguro: VehiculoCompaniaSeguro.ALEDONIA,
    vencimientoPoliza: moment(),
    fotoPoliza: '',
    vencimientoHabilitacion: moment(),
    fotoHabilitacion: '',
    vencimientoCuotaSeguro: moment(),
    fotoCuotaSeguro: '',
    vencimientoMicrocentro: null,
    fotoMicrocentro: '',
    municipio: VehiculoMunicipio.AVELLANEDA
  };
}

export function vehiculoFormSchemaToModel(
  vehiculoFormValues: VehiculoFormSchema,
  titulares: TitularModel[]
): VehiculoCRUD {
  return {
    fechaIngreso: vehiculoFormValues.general.fechaIngreso,
    foto: vehiculoFormValues.general.fotoAuto,
    patente: vehiculoFormValues.general.patente,
    titular: vehiculoFormValues.general.titular,
    modelo: vehiculoFormValues.general.modelo,
    color: vehiculoFormValues.general.color,
    anio: vehiculoFormValues.general.anio,
    chasis: vehiculoFormValues.general.chasis,
    combustible: vehiculoFormValues.general.combustible,
    tipo: vehiculoFormValues.general.tipo,
    carbono: Number(vehiculoFormValues.general.carbono),
    categoria: vehiculoFormValues.general.categoria,
    vtv: {
      fechaVencimiento: vehiculoFormValues.vencimientos.vencimientoVTV,
      foto: vehiculoFormValues.vencimientos.fotoVTV
    },
    seguro: {
      compania: vehiculoFormValues.vencimientos.companiaSeguro,
      poliza: {
        fechaVencimiento: vehiculoFormValues.vencimientos.vencimientoPoliza,
        foto: vehiculoFormValues.vencimientos.fotoPoliza
      },
      cuota: {
        fechaVencimiento:
          vehiculoFormValues.vencimientos.vencimientoCuotaSeguro,
        foto: vehiculoFormValues.vencimientos.fotoCuotaSeguro
      }
    },
    habilitacion: {
      fechaVencimiento: vehiculoFormValues.vencimientos.vencimientoHabilitacion,
      foto: vehiculoFormValues.vencimientos.fotoHabilitacion
    },
    microcentro: vehiculoFormValues.vencimientos.vencimientoMicrocentro
      ? {
          fechaVencimiento:
            vehiculoFormValues.vencimientos.vencimientoMicrocentro,
          foto: vehiculoFormValues.vencimientos.fotoMicrocentro!
        }
      : null,
    municipio: vehiculoFormValues.vencimientos.municipio,
    bus_type_id: vehiculoFormValues.general.bus_type_id
  };
}

export function vehiculoModelToFormSchema(
  vehiculo: VehiculoModel
): VehiculoFormSchema {
  return {
    general: vehiculoModelToFormSchemaGeneral(vehiculo),
    vencimientos: vehiculoModelToFormSchemaVencimientos(vehiculo)
  };
}

function vehiculoModelToFormSchemaGeneral(
  vehiculo: VehiculoModel
): VehiculoFormSchemaGeneral {
  return {
    fechaIngreso: vehiculo.fechaIngreso,
    fotoAuto: vehiculo.foto,
    patente: vehiculo.patente,
    titular: vehiculo.titular,
    modelo: vehiculo.modelo,
    color: vehiculo.color,
    anio: vehiculo.anio,
    chasis: vehiculo.chasis,
    combustible: vehiculo.combustible,
    tipo: vehiculo.tipo,
    categoria: vehiculo.categoria,
    carbono: vehiculo.carbono === null ? '' : vehiculo.carbono,
    bus_type_id: vehiculo.bus_type_id ?? null
  };
}

function vehiculoModelToFormSchemaVencimientos(
  vehiculo: VehiculoModel
): VehiculoFormSchemaVencimiento {
  return {
    vencimientoVTV: vehiculo.vtv.fechaVencimiento,
    fotoVTV: vehiculo.vtv.foto,
    companiaSeguro: vehiculo.seguro.compania,
    vencimientoPoliza: vehiculo.seguro.poliza.fechaVencimiento,
    fotoPoliza: vehiculo.seguro.poliza.foto,
    vencimientoHabilitacion: vehiculo.habilitacion.fechaVencimiento,
    fotoHabilitacion: vehiculo.habilitacion.foto,
    vencimientoMicrocentro: vehiculo.microcentro
      ? vehiculo.microcentro.fechaVencimiento
      : null,
    fotoMicrocentro: vehiculo.microcentro
      ? vehiculo.microcentro.foto
      : undefined,
    vencimientoCuotaSeguro: vehiculo.seguro.cuota.fechaVencimiento,
    fotoCuotaSeguro: vehiculo.seguro.cuota.foto,
    municipio: vehiculo.municipio
  };
}
