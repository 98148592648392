import { Table, TableColumn } from '@vadiun/react-datatable';
import { LiquidacionModel } from '../models/Liquidacion';
import ActionsTable from './ActionsTable';
import { formatNumberToPrice } from 'shared/helpers';

interface Props {
  data: LiquidacionModel[];
  setSelectedRows: (x: LiquidacionModel[]) => void;
  selectedLiquidaciones: LiquidacionModel[];
  onDeleteReservations: (id: string) => void;
  onDeleteLiquidacion: (id: string) => void;
}

const LiquidacionTable = (props: Props) => {
  const data = props.data.map((res) => ({
    ...res,
    clientName: res.client.name,
    datetime: res.datetime.format('DD/MM/YYYY')
  }));

  const columns: TableColumn<typeof data[0]>[] = [
    {
      name: 'id',
      label: 'ID'
    },
    {
      name: 'clientName',
      label: 'Cliente'
    },
    {
      name: 'business_unit_breakdown',
      label: 'Detalle por unidad',
      options: {
        filter: false,
        customBodyRenderLite: (index) => {
          const current = props.data[index];
          const breakdown = current?.business_unit_breakdown;
          return (
            <div className="space-y-1 text-gray-500">
              <div className="flex justify-between">
                <p>Envios:</p>
                <span>${formatNumberToPrice(breakdown?.envios ?? 0)}</span>
              </div>
              <div className="flex justify-between">
                <p>LLT-País:</p>
                <span>${formatNumberToPrice(breakdown?.lltpais ?? 0)}</span>
              </div>
              <div className="flex justify-between">
                <p>Remises:</p>
                <span>${formatNumberToPrice(breakdown?.remises ?? 0)}</span>
              </div>
              <div className="flex justify-between">
                <p>SG:</p>
                <span>${formatNumberToPrice(breakdown?.buses ?? 0)}</span>
              </div>
              <div className="border-t-2 border-dashed pt-1 flex justify-between">
                <p>Total:</p>
                <span>${formatNumberToPrice(breakdown?.total ?? 0)}</span>
              </div>
            </div>
          );
        }
      }
    },
    {
      name: 'datetime',
      label: 'Fecha'
    },
    {
      name: 'total_transfer_price',
      label: 'Total viaje'
    },

    {
      name: 'total_expenses',
      label: 'Total gastos'
    },
    {
      name: 'total_price',
      label: 'Precio final'
    },
    {
      label: 'Acciones',
      name: 'id',

      options: {
        download: false,
        setCellHeaderProps: () => ({
          style: { textAlign: 'right' }
        }),
        setCellProps: () => ({
          width: '100px'
        }),
        customBodyRenderLite: (index) => {
          const current = props.data[index];
          return (
            <ActionsTable
              client={current.client.name}
              settlementId={String(current.id)}
              onDeleteReservations={props.onDeleteReservations}
              onDeleteLiquidacion={props.onDeleteLiquidacion}
            />
          );
        }
      }
    }
  ];

  return (
    <div className="w-full">
      <Table
        data={data}
        options={{
          elevation: 0,
          search: false,
          filter: false,
          sort: false,
          selectableRows: 'multiple',
          downloadOptions: {
            filename: 'liquidaciones'
          },
          rowsSelected: props.selectedLiquidaciones.map((liq) =>
            data.findIndex((d) => d.id === liq.id)
          ),
          selectToolbarPlacement: 'none',
          onRowSelectionChange: (_, selection) => {
            props.setSelectedRows(
              selection.map(({ dataIndex }) => props.data[dataIndex])
            );
          }
        }}
        title={''}
        columns={columns}
      />
    </div>
  );
};
export default LiquidacionTable;
